import { render, staticRenderFns } from "./chatPDF-home.vue?vue&type=template&id=69860988&scoped=true&"
import script from "./chatPDF-home.vue?vue&type=script&lang=js&"
export * from "./chatPDF-home.vue?vue&type=script&lang=js&"
import style0 from "./chatPDF-home.vue?vue&type=style&index=0&id=69860988&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69860988",
  null
  
)

export default component.exports